import { CameraAlt, Edit } from '@mui/icons-material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, EditProfileButton, ImageContainer, Toolbar } from './styles';
import { useNavigate } from 'react-router-dom';
import { getSystemImageUrl } from 'utils/assets';
import useSnackbar from 'hooks/useSnackbar';
import CropImage from 'components/molecules/functionalities/CropImage';
import { updateBackground } from 'services/profile';

const ProfileHeader = ({ image, background, enterprise, profile, participant }) => {
    const [backgroundFile, setBackgroundFile] = useState();
    const [backgroundUrl, setBackgroundUrl] = useState(background);

    const cameraRef = useRef(null);

    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const getUrlBackgroundImage = () => {
        if (backgroundUrl) {
            return backgroundUrl;
        }

        return enterprise || participant
            ? getSystemImageUrl('participant-background.png')
            : 'https://pfaapi.rehagro.com.br/storage/images_competences/Rectangle_726.png';
    };

    const saveImage = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];

        if (!validImageTypes.includes(fileType)) {
            snackbar('Arquivo inválido, selecione uma imagem tipo JPG ou PNG', 'error');
            return;
        }

        if (file.size > 30000000) {
            snackbar('Imagem muito grande, selecione uma image menor que 30MB', 'error');
            return;
        }

        setBackgroundFile(file);
    };

    const onCrop = async (url, image) => {
        const formData = new FormData();

        formData.append('image', image);

        await updateBackground(formData)
            .then(() => {
                setBackgroundUrl(url);
                setBackgroundFile(null);
            })
            .catch(() => {
                snackbar('Erro ao atualizar imagem de fundo', 'error');
            });
    };

    return (
        <FullWidthContainer marginTop="0 !important">
            <Container background={getUrlBackgroundImage()}>
                <ImageContainer src={image ? image : ''} isCircle={!enterprise} />
                {profile && (
                    <Toolbar>
                        <EditProfileButton onClick={() => navigate('/aluno/perfil/editar')}>
                            <Edit />
                        </EditProfileButton>

                        <input type="file" accept="image/*" onChange={(e) => saveImage(e.target.files[0])} hidden ref={cameraRef} />

                        <EditProfileButton onClick={() => cameraRef?.current?.click()}>
                            <CameraAlt />
                        </EditProfileButton>
                    </Toolbar>
                )}
            </Container>

            {backgroundFile && <CropImage aspect={4 / 1} imageSrc={URL.createObjectURL(backgroundFile)} updateImageByCropped={onCrop} />}
        </FullWidthContainer>
    );
};

ProfileHeader.propTypes = {
    image: PropTypes.string,
    background: PropTypes.string,
    enterprise: PropTypes.bool,
    profile: PropTypes.bool,
    participant: PropTypes.bool
};

export default ProfileHeader;
