import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import ProfileRedirect from 'components/molecules/cards/ProfileRedirect';
import LevelCarousel from 'components/molecules/carousels/LevelCarousel';
import ProfileAbout from 'components/organisms/containers/ProfileAbout';
import ProfileLastCertificate from 'components/organisms/containers/ProfileLastCertificate';
import UserDescription from 'components/organisms/containers/UserDescription';
import ProfileListAbout from 'components/organisms/lists/ProfileListAbout';
import PfaContents from 'components/organisms/tabsSections/PfaContents';
import Meta from 'components/templates/Seo/Meta';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getProfileById } from 'services/profile';
import { getStudentLevel } from 'services/level';
import useSnackbar from 'hooks/useSnackbar';
import { getSystemImageUrl } from 'utils/assets';
import { getEnterpriseById } from 'services/enterprise';
import EnterpriseDescription from 'components/organisms/containers/EnterpriseDescription';
import EnterpriseContent from 'components/organisms/containers/EnterpriseContent';
import OpportunityEnterpriseList from 'components/organisms/lists/OpportunityEnterpriseList';

const Perfil = () => {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const auth = useSelector((state) => state.auth);
    const [error, setError] = useState('');

    const snackbar = useSnackbar();
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(null);

    const getProfile = async () => {
        if (!auth?.user || profile?.id) {
            return;
        }

        const data = await getProfileById(auth.user.studentId).then((response) => response);

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(...data.data);
        setLoading(false);
    };

    const getEnterprise = async () => {
        if (!auth?.user || profile?.id) {
            return;
        }

        const data = await getEnterpriseById(auth.user.enterpriseId, true).then((response) => response);

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(...data.data);
        setLoading(false);
    };

    const getCurrentLevel = async () => {
        const getProgress = (level, myLevel) => {
            if (!myLevel) {
                return 0;
            }

            return myLevel?.id >= level.id ? 100 : level.id === myLevel.nextLevel.id ? myLevel.nextLevel.nextPercent : 0;
        };

        await getStudentLevel()
            .then((res) => {
                const levels = res.data.levelsExperiences.map((level) => {
                    return {
                        id: level.id,
                        level: level.level,
                        name: level.name,
                        progress: getProgress(level, res.data.levelExperience),
                        blocked: level.blocked
                    };
                });

                setLevels(levels);

                const isMyLevelBlocked = levels.find((level) => level.id === res.data.levelExperience?.id)?.blocked;

                setCurrentLevel(res.data.levelExperience.id - (isMyLevelBlocked ? 1 : 0));
            })
            .catch((error) => {
                snackbar(error, 'error');
                setLoading(false);
            });
    };

    useEffect(() => {
        getCurrentLevel();
    }, []);

    useEffect(() => {
        if (!auth?.user?.studentId && auth.user?.enterpriseId) {
            return;
        }

        if (auth.user?.enterpriseId) {
            getEnterprise();
            return;
        }

        if (auth?.user.studentId) {
            getProfile(auth?.user.studentId);
        }
    }, [auth?.user.studentId, auth]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !profile?.id) {
        return <Message text={error} />;
    }

    if (auth?.user?.enterpriseId) {
        return (
            <>
                <EnterpriseDescription
                    image={profile?.url_image}
                    name={profile.name}
                    city={profile.city}
                    description={profile.description}
                    state={profile.uf}
                    background={profile?.url_background_image}
                    profile
                />

                <EnterpriseContent overview={profile.overview} age={profile.age} site={profile.site} size={profile.size} />

                <ProfileRedirect
                    imageUrl="https://pfaapi.rehagro.com.br/storage/images_competences/Group_349.png"
                    href="/aluno/perfil/competencias"
                    title="Veja suas Competências"
                    description="Evolua o seu nível no PFA através do desenvolvimento de suas competências."
                />

                {profile.sector && (
                    <ProfileListAbout title="Atuação" data={[profile.sector]} icon={getSystemImageUrl('brief.svg')} border />
                )}

                <OpportunityEnterpriseList data={profile.opportunityEnterprise} />
            </>
        );
    }

    return (
        <>
            <Meta title="Perfil" />

            <UserDescription
                image={profile?.url_image}
                name={profile.name}
                city={profile.city}
                occupation={profile.occupation}
                state={profile.uf}
                background={profile?.url_background_image}
            />

            <LevelCarousel data={levels} currentLevel={currentLevel} />

            <ProfileRedirect
                imageUrl="https://pfaapi.rehagro.com.br/storage/images_competences/Group_349.png"
                href="/aluno/perfil/competencias"
                title="Veja suas Competências"
                description="Evolua o seu nível no PFA através do desenvolvimento de suas competências."
            />

            <ProfileAbout text={profile.description} />

            <ProfileLastCertificate />

            {profile.academicEducation !== undefined && profile.academicEducation.length > 0 && (
                <ProfileListAbout
                    title="Formação Acadêmica:"
                    data={profile.academicEducation}
                    icon={getSystemImageUrl('certificate.svg')}
                />
            )}

            {profile.courseAndCertificate !== undefined && profile.courseAndCertificate.length > 0 && (
                <ProfileListAbout title="Cursos e certificados:" data={profile.courseAndCertificate} icon={getSystemImageUrl('work.svg')} />
            )}

            {profile.language !== undefined && profile.language.length > 0 && (
                <ProfileListAbout title="Idiomas:" data={profile.language} icon={getSystemImageUrl('translate.svg')} />
            )}

            {profile.experience !== undefined && profile.experience.length > 0 && (
                <ProfileListAbout title="Experiências:" data={profile.experience} icon={getSystemImageUrl('briefcase.svg')} />
            )}

            <PfaContents events={profile?.participationEventInPfa} courses={profile?.participationCourseInPfa} />
        </>
    );
};

export default Perfil;
