import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import {
    CommentModal,
    Container,
    Divisor,
    Emojis,
    FormContainer,
    HeaderTitle,
    ModalContainer,
    ModalPost,
    ModalPostText,
    ModalPostTitle,
    MoreComments
} from './styles';
import { Avatar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useFeedback from 'hooks/useFeedback';
import { getPublicationComments, postPublicationComment } from 'services/publication/comment';

import Modal from 'components/organisms/layout/modals/Modal';
import Message from 'components/molecules/alerts/Message';
import PublicationComments from 'components/molecules/containers/PublicationComments';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';

const PublicationComment = ({ publication, modalOpen, setModalOpen }) => {
    const hook = useFeedback();
    const [publicationComments, setPublicationComments] = useState({ loading: false, data: [] });
    const [publicationCommentsPage, setPublicationCommentsPage] = useState(1);
    const [commentPublishing, setCommentPublishing] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const timeline = useSelector((state) => state.timeline);

    const icons = ['💚', '⭐', '😍', '😀', '😯', '👏', '👍'];

    const getPublicationCommentsData = async () => {
        setPublicationCommentsPage(1);

        setPublicationComments({ ...publicationComments, loading: true });
        let { data } = await getPublicationComments({
            id_publication: publication.id,
            order: 'id:asc',
            page: 1
        });

        setPublicationComments({ ...data, loading: false });
    };

    const getPublicationCommentsDataAppend = async () => {
        setPublicationCommentsPage(publicationCommentsPage + 1);
        setPublicationComments({ ...publicationComments, loading: true });

        let { data } = await getPublicationComments({
            id_publication: publication.id,
            order: 'id:asc',
            page: publicationCommentsPage + 1
        });

        setPublicationComments({
            ...publicationComments,
            data: [...publicationComments.data, ...data.data],
            loading: false
        });
    };

    const handleModalOpen = async () => {
        setModalOpen(true);
        formikComment.setFieldValue('id_publication', publication?.id);
        formikComment.setFieldValue('id_reply', null);
        setPublicationCommentsPage(1);
        await getPublicationCommentsData();
    };

    const handleModalClose = (ev) => {
        setModalOpen(false);
        hook.closeFeedback(ev);
    };

    const formikComment = useFormik({
        initialValues: { id_publication: publication.id, id_reply: null, comment: '' },

        onSubmit: async (values) => {
            if (!values.comment) return;

            formikComment.setFieldValue('comment', '');
            setCommentPublishing(true);
            await postPublicationComment(values);
            await getPublicationCommentsData();
            setCommentPublishing(false);

            let timelineItem = timeline.items.filter((item) => item.id == publication.id)[0] || false;
            if (!timelineItem) return;
            timelineItem = new Function(`return ${JSON.stringify(timelineItem)}`)();
            timelineItem.commentsTotal += 1;
            if (publicationComments[0]) timelineItem.comments = [publicationComments[0]];
            dispatch({ type: 'SET_TIMELINE_ITEM', payload: { item: timelineItem } });
        }
    });

    const emojiAddHandle = (emoji) => {
        const comment = [formikComment.values.comment, emoji].join('');
        formikComment.setFieldValue('comment', comment);
    };

    const textareaOnChange = (ev) => {
        ev.target.style.height = '26px';
        ev.target.style.height = ev.target.scrollHeight + 'px';
        formikComment.handleChange(ev);
    };

    useEffect(() => {
        if (modalOpen === false) return;
        handleModalOpen();
    }, [modalOpen]);

    const DynamicAvatar = () => {
        if (publication.typeText == 'Oportunidade' || publication.typeText == 'Gravação do evento') {
            return <Avatar src="https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png" />;
        }

        const validUrl = (() => {
            try {
                new URL(publication?.user?.image);
                return publication?.user?.image;
            } catch (err) {
                return null;
            }
        })();

        return <Avatar src={validUrl} />;
    };

    return (
        <Container>
            <IconButton aria-label="toggle password visibility" onClick={handleModalOpen}>
                <Svg url={getSystemImageUrl('ChatTeardropText.svg')} width="28" height="28" />
            </IconButton>

            <Modal open={modalOpen} title="Comentar" handleClose={handleModalClose}>
                <ModalContainer>
                    <CommentModal>
                        <header>
                            <HeaderTitle>Comentar</HeaderTitle>
                            <IconButton aria-label="Close modal" size="small" onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </header>
                        <main>
                            <ModalPost>
                                <header>
                                    <DynamicAvatar />
                                </header>
                                <footer>
                                    <ModalPostTitle>{publication.title || publication?.user?.name}</ModalPostTitle>
                                    <ModalPostText>
                                        {ReactHtmlParser(htmlClean(publication.description || publication.shortDescription))}
                                    </ModalPostText>
                                </footer>
                            </ModalPost>

                            <Divisor />

                            {publicationComments.loading === true && publicationComments.data.length == 0 && (
                                <Message html text="Carregando..." />
                            )}

                            {publicationComments.loading === false && publicationComments.data.length == 0 && (
                                <Message html text="Nenhum comentário" />
                            )}

                            <PublicationComments comments={publicationComments.data} />

                            {publicationComments.last_page &&
                                publicationComments.last_page > 1 &&
                                publicationComments.last_page > publicationCommentsPage && (
                                    <MoreComments type="submit" onClick={getPublicationCommentsDataAppend}>
                                        {publicationComments.loading ? 'Carregando' : 'Carregar mais comentários'}
                                    </MoreComments>
                                )}
                        </main>

                        <footer>
                            <Emojis>
                                {icons.map((emoji, index) => (
                                    <button
                                        type="button"
                                        key={index}
                                        onClick={() => {
                                            emojiAddHandle(emoji);
                                        }}
                                    >
                                        <span role="img" aria-label="Emojis">
                                            {emoji}
                                        </span>
                                    </button>
                                ))}
                            </Emojis>

                            <form onSubmit={formikComment.handleSubmit}>
                                <FormContainer>
                                    <header>
                                        <Avatar src={user.image} />
                                    </header>
                                    <main>
                                        <textarea
                                            name="comment"
                                            placeholder={commentPublishing ? 'Enviando...' : 'Adicione um comentário'}
                                            value={formikComment.values.comment}
                                            onChange={textareaOnChange}
                                            disabled={commentPublishing}
                                        />
                                    </main>
                                    <footer>
                                        <button type="submit" disabled={commentPublishing || !formikComment.values.comment}>
                                            Publicar
                                        </button>
                                    </footer>
                                </FormContainer>
                            </form>
                        </footer>
                    </CommentModal>
                </ModalContainer>
            </Modal>
        </Container>
    );
};

PublicationComment.propTypes = {
    publication: PropTypes.object.isRequired,
    modalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func
};

PublicationComment.defaultProps = {
    modalOpen: false
};

export default PublicationComment;
