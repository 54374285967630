import { Avatar } from '@mui/material';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import Chips from 'components/molecules/containers/Chips';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Container, ImageContainer, DescriptionText, PastEventMessage } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { likePost } from 'services/timeline';
import useSnackbar from 'hooks/useSnackbar';
import { seePost } from 'services/post';
import { getFallbackUrl } from 'utils/general';

import PublicationComments from 'components/molecules/containers/PublicationComments';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';

const TimelineCard = ({ post }) => {
    const [liked, setLiked] = useState(post.liked);
    const [uri, setUri] = useState('');
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);

    const handleLike = async (value) => {
        await likePost(post.id)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir postagem', 'error');
            });

        setLiked(value);
    };

    const { typeAdvertisement } = post;

    const getUri = () => {
        if (typeAdvertisement === 'Curso') {
            return getFallbackUrl(`/aluno/cursos/curso/${post.itemId}`, 'Home');
        }

        if (typeAdvertisement === 'Evento') {
            return getFallbackUrl(`/aluno/eventos/${post.itemId}`, 'Home');
        }

        if (typeAdvertisement === 'Gravação do evento') {
            return getFallbackUrl(`/aluno/biblioteca/conteudo/${post.itemId}`, 'Home');
        }

        return '';
    };

    const redirect = async () => {
        if (!post.sawed) {
            await seePost(Number(post.id));
        }
        const uri = getUri();

        navigate(uri);
    };

    const buttonText = typeAdvertisement === 'Gravação do evento' ? 'Assistir evento' : 'Saiba mais';

    useEffect(() => {
        setUri(getUri());
    }, [post]);

    return (
        <Container>
            <Link to={uri}>
                <header>
                    <aside>
                        <Avatar src="https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png" />
                    </aside>
                    <main>
                        <h3>{post.title}</h3>
                        <Chips
                            data={post.competences.map((item) => {
                                return { description: item.desription, color: item.color };
                            })}
                        />
                    </main>
                </header>
            </Link>
            <Link to={uri}>
                <main>
                    <ImageContainer>
                        {post.image ? (
                            <img src={post.image} alt={post.title} />
                        ) : (
                            <img src="https://via.placeholder.com/160x90" alt="descrição do curso" />
                        )}

                        {post.isFinished && (
                            <PastEventMessage>
                                <div>
                                    <span>Evento encerrado</span>
                                </div>
                            </PastEventMessage>
                        )}
                    </ImageContainer>

                    <DescriptionText>{ReactHtmlParser(htmlClean(post.description).split('</p>')[0])}</DescriptionText>

                    <footer>
                        <Button size="fat" type="button" width="auto" color="neutral" onClick={redirect}>
                            {buttonText}
                        </Button>
                    </footer>
                </main>
            </Link>

            <div className="divider" />
            <PublicationComments
                comments={post.comments}
                ellipsis={true}
                redirectOnClick={false}
                clickHandle={() => setModalOpen(!modalOpen)}
            />

            <footer>
                <aside>
                    <div className="footer-aside__item">
                        <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                        <p>{post.likes + liked - post.liked}</p>
                    </div>
                    <div className="footer-aside__item">
                        <PublicationCommentBtn publication={post} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        <p>{post.commentsTotal}</p>
                    </div>
                </aside>
            </footer>
        </Container>
    );
};

TimelineCard.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default TimelineCard;
